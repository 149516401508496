* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
}

html {
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito', sans-serif;
}

body {
  background-color: #f9fafb;
  font-style: normal;
  color: #2c2b2b;
  padding-right: 0 !important;
  //overflow: auto !important;
}

a {
  text-decoration: none;
  color: unset;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px;
  padding: 15px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: #f1f1f1;
}

.socialShareIcons {
  width: fit-content;
  cursor: pointer;
  padding: 5px;
}

.MuiBadge-badge {
  margin-top: -4px;
  font-size: 10px !important;
  height: 15px !important;
  min-width: 15px !important;
}

.MuiBadge-dot {
  height: 6px !important;
  padding: 0;
  min-width: 6px !important;
}

.postHigAtt{
  background: linear-gradient(#d1fdc2, #d4f1ff) !important;
}

.postAvgAtt{
  background: #f4f6f7 !important;;
}

.postLowAtt {
  background: linear-gradient(#f1e4d0, #e4e0cf) !important;
}

.MuiAvatar-fallback {
  display: none !important;
}

.tikUsed:hover .hoverText {
  display: none !important;
}

.tikUsed {
  background: #43a048 !important;
  color: #fff !important;;
}

.tikUsed:hover {
  background: #981e20 !important;
}

.tikUsed:hover:before {
  content: "Uncheck" !important;
}

.paymentCardWrapper:hover {
  opacity: 1 !important;
  background: #e5e5e5;

}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.linkGenerated {
  color: #385898;
}

.linkGenerated:hover {
  text-decoration: underline;
}

///* width */
//::-webkit-scrollbar {
//  width: 5px;
//}
///* Track */
//::-webkit-scrollbar-track {
//  border-radius: 5px;
//}
///* Handle */
//::-webkit-scrollbar-thumb {
//  border-radius: 10px;
//  background-color: #FFF;
//  background-image: none;
//}
///* Handle on hover */
//::-webkit-scrollbar-thumb:hover {
//  background: unset;
//}