@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
*{box-sizing:border-box;margin:0;padding:0;overflow-wrap:break-word}html{font-size:1rem;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-family:'Nunito', sans-serif}body{background-color:#f9fafb;font-style:normal;color:#2c2b2b;padding-right:0 !important}a{text-decoration:none;color:unset}.StripeElement{display:block;margin:10px 0 20px;padding:15px 14px;font-size:1em;font-family:'Source Code Pro', monospace;box-shadow:rgba(50,50,93,0.14902) 0px 1px 3px,rgba(0,0,0,0.0196078) 0px 1px 0px;border:0;outline:0;border-radius:4px;background:#f1f1f1}.socialShareIcons{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;cursor:pointer;padding:5px}.MuiBadge-badge{margin-top:-4px;font-size:10px !important;height:15px !important;min-width:15px !important}.MuiBadge-dot{height:6px !important;padding:0;min-width:6px !important}.postHigAtt{background:linear-gradient(#d1fdc2, #d4f1ff) !important}.postAvgAtt{background:#f4f6f7 !important}.postLowAtt{background:linear-gradient(#f1e4d0, #e4e0cf) !important}.MuiAvatar-fallback{display:none !important}.tikUsed:hover .hoverText{display:none !important}.tikUsed{background:#43a048 !important;color:#fff !important}.tikUsed:hover{background:#981e20 !important}.tikUsed:hover:before{content:"Uncheck" !important}.paymentCardWrapper:hover{opacity:1 !important;background:#e5e5e5}.videoWrapper{position:relative;padding-bottom:56.25%;padding-top:25px;height:0}.videoWrapper iframe{position:absolute;top:0;left:0;width:100%;height:100%}.linkGenerated{color:#385898}.linkGenerated:hover{text-decoration:underline}.ps__rail-y{right:0 !important;left:auto !important}.ps__rail-x{bottom:0 !important;top:auto !important}

.image-upload-container {
    position: relative;
    cursor: pointer;
    background: aliceblue;
    overflow: hidden;
}

.image-upload-imageShown {
    opacity: 1;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

}

.image-upload-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.image-upload-container:hover > .image-upload-container {
    background: #000000;
}

.image-upload-container:hover > .image-upload-imageShown {
    opacity: 0.3;
}

.image-upload-container:hover > .image-upload-middle {
    opacity: 1;
}

.image-upload-text {
    border-radius: 5px;
    background-color: #444744;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
}
.grid-container {
    text-align: center;
    margin: auto;
    width: 100%;
    padding: 10px 5px;
}

.container {
    padding-left: 0;
    padding-right: 0;
    width: 100% !important;
}

.container * {
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.col-md-4 {
    width: 33.33333333%;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.border {
    border: 2px solid white;
    border-radius: 6px;
}

.background {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.height-one {
    width: 100%;
    padding-top: 66%;
}

.height-two {
    width: 50%;
    padding-top: 50%;
}

.height-three {
    width: 33.3333%;
    padding-top: 33.3333%;
}

.cover {
    background-color: #222;
    opacity: 0.8;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 6px;
}

.cover-text {
    right: 0;
    left: 0;
    bottom: 0;
    color: white;
    font-size: 7%;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    text-align: center;
}

.cover-text > p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slide {
    height: 0;
    bottom: 100%;
    transition: .5s ease;
    overflow: hidden;
    font-size: 3%;
}

.border:hover .slide {
    bottom: 0;
    height: auto;
}

.border:hover .animate-text {
    top: 62%
}
/*
Created by Matej Kovac
http://moodydev.io
*/
/* FONT IMPORTS */

.icon {
    font-family: "Material Icons", serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
}

/* VARIABLES */

:root {
    --main-color: #1a8fff;
    --text-color: #898989;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
    --active-day-color: #0077e0;
}


/*body {*/
/*    font-family: 'Nunito', sans-serif;*/
/*    font-size: 1em;*/
/*    font-weight: 300;*/
/*    line-height: 1.5;*/
/*    color: var(--text-color);*/
/*    background: var(--bg-color);*/
/*}*/

/* GRID */

.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.row-middle {
    align-items: center;
}

.col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

.col-start {
    justify-content: flex-start;
    text-align: left;
}

.col-center {
    justify-content: center;
    text-align: center;
}

.col-end {
    justify-content: flex-end;
    text-align: right;
}

/* Calendar */

.prevMonth:hover {
    color: var(--main-color);
}

.currentMonth {
    color: #b71c1c;
    cursor: pointer;
}

.currentMonthRead {
    margin-right: 1em;
}

.monthPre {
    vertical-align: middle;
}

.calendarTitle {
    clear: both;
    padding: 10px 5px 16px;
    text-align: center;
    font-weight: 700;
}
.calendar {
    z-index: 0;
    clear: both;
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
}

.calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 85%;
    padding: 1.5em 0;
    border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
    background: #e5e5e5;
    border-radius: 6px;
    padding: 5px;
    cursor: pointer;
    transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
    color: var(--main-color);
}

.calendar .header .icon:first-of-type {
    margin-left: 1em;
}

.calendar .header .icon:last-of-type {
    margin-right: 1em;
}

.calendar .days {
    background-color: #f8f8f8;
    font-weight: 700;
    color: #898989;
    font-size: 100%;
    padding: 0.75em 0;
    border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
    color: #666;
    position: relative;
    height: 5em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
}

.calendar .body .cell:hover {
    background-color: var(--active-day-color);
    color: #e5e5e5 !important;
}

.calendar .body .selected, .calendar .body .selected:hover {
    background-color: var(--active-day-color);
    color: #e5e5e5;
}

.calendar .body .row {
    border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
    border-bottom: none;
}

.calendar .body .cell:last-child {
    border-right: none;
}

.calendar .body .cell .number {
    position: relative;
    font-size: 110%;
    line-height: 5;
    z-index: 99999;
}

.calendar .body .cell .dateEvent {
    font-size: 61%;
    width: 100%;
    position: absolute;
    z-index: 99;
    left: 0;
    top: 3px;
    text-align: center;
    line-height: 11px;
}

.calendar .body .disabled {
    color: var(--text-color-light) !important;
    pointer-events: none;
    opacity: 0.4;
}

.calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: -0.2em;
    right: -0.05em;
    letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
    opacity: 0.05;
}

.calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
    text-align: center;
}

.buttonWrapper {
    margin-bottom: 10px
}

.addEventWrapper {
    padding: 15px 24px
}

.floatRight {
    float: right;
}

.floatLeft {
    float: left;
}

.clearBoth {
    clear: both;
}
.selected .holidayDate {
    background-color: #b71c1c;
    color: #ffffff !important;
}

.calendar .body .cell .holidayDate {
    color: #b71c1c;
}

.calendar .body .cell .holidayDate:hover {
    color: #ffffff;
}

@media only screen and (max-width: 400px) {
    .calendar .body .cell .number {
        line-height: 3em;
    }

    .calendar .body .cell {
        height: 3em;
    }
}
