/*
Created by Matej Kovac
http://moodydev.io
*/
/* FONT IMPORTS */
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
    font-family: "Material Icons", serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
}

/* VARIABLES */

:root {
    --main-color: #1a8fff;
    --text-color: #898989;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
    --active-day-color: #0077e0;
}


/*body {*/
/*    font-family: 'Nunito', sans-serif;*/
/*    font-size: 1em;*/
/*    font-weight: 300;*/
/*    line-height: 1.5;*/
/*    color: var(--text-color);*/
/*    background: var(--bg-color);*/
/*}*/

/* GRID */

.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.row-middle {
    align-items: center;
}

.col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

.col-start {
    justify-content: flex-start;
    text-align: left;
}

.col-center {
    justify-content: center;
    text-align: center;
}

.col-end {
    justify-content: flex-end;
    text-align: right;
}

/* Calendar */

.prevMonth:hover {
    color: var(--main-color);
}

.currentMonth {
    color: #b71c1c;
    cursor: pointer;
}

.currentMonthRead {
    margin-right: 1em;
}

.monthPre {
    vertical-align: middle;
}

.calendarTitle {
    clear: both;
    padding: 10px 5px 16px;
    text-align: center;
    font-weight: 700;
}
.calendar {
    z-index: 0;
    clear: both;
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
}

.calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 85%;
    padding: 1.5em 0;
    border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
    background: #e5e5e5;
    border-radius: 6px;
    padding: 5px;
    cursor: pointer;
    transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
    color: var(--main-color);
}

.calendar .header .icon:first-of-type {
    margin-left: 1em;
}

.calendar .header .icon:last-of-type {
    margin-right: 1em;
}

.calendar .days {
    background-color: #f8f8f8;
    font-weight: 700;
    color: #898989;
    font-size: 100%;
    padding: 0.75em 0;
    border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
    color: #666;
    position: relative;
    height: 5em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
}

.calendar .body .cell:hover {
    background-color: var(--active-day-color);
    color: #e5e5e5 !important;
}

.calendar .body .selected, .calendar .body .selected:hover {
    background-color: var(--active-day-color);
    color: #e5e5e5;
}

.calendar .body .row {
    border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
    border-bottom: none;
}

.calendar .body .cell:last-child {
    border-right: none;
}

.calendar .body .cell .number {
    position: relative;
    font-size: 110%;
    line-height: 5;
    z-index: 99999;
}

.calendar .body .cell .dateEvent {
    font-size: 61%;
    width: 100%;
    position: absolute;
    z-index: 99;
    left: 0;
    top: 3px;
    text-align: center;
    line-height: 11px;
}

.calendar .body .disabled {
    color: var(--text-color-light) !important;
    pointer-events: none;
    opacity: 0.4;
}

.calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: -0.2em;
    right: -0.05em;
    letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
    opacity: 0.05;
}

.calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
    text-align: center;
}

.buttonWrapper {
    margin-bottom: 10px
}

.addEventWrapper {
    padding: 15px 24px
}

.floatRight {
    float: right;
}

.floatLeft {
    float: left;
}

.clearBoth {
    clear: both;
}
.selected .holidayDate {
    background-color: #b71c1c;
    color: #ffffff !important;
}

.calendar .body .cell .holidayDate {
    color: #b71c1c;
}

.calendar .body .cell .holidayDate:hover {
    color: #ffffff;
}

@media only screen and (max-width: 400px) {
    .calendar .body .cell .number {
        line-height: 3em;
    }

    .calendar .body .cell {
        height: 3em;
    }
}