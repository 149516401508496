.image-upload-container {
    position: relative;
    cursor: pointer;
    background: aliceblue;
    overflow: hidden;
}

.image-upload-imageShown {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: hidden;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

}

.image-upload-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.image-upload-container:hover > .image-upload-container {
    background: #000000;
}

.image-upload-container:hover > .image-upload-imageShown {
    opacity: 0.3;
}

.image-upload-container:hover > .image-upload-middle {
    opacity: 1;
}

.image-upload-text {
    border-radius: 5px;
    background-color: #444744;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
}